.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; 
  background-color: #f8f9fa; 
}

body {
  padding-top: 80px; 
}


.info-box:hover {
  transform: translateY(-18px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  padding: 0px;
  margin: 0px;
}

/* --------------menu link------------ */

#menu .nav-menu .nav-list {
  position: relative;
  font-weight: bold;
  color: BLACK;
  transition: 0.10s;
}

#menu .nav-menu .nav-list::after {
  content: "";
  position: absolute;
  top: 36px;
  left: 0;
  background-color: black;
  width: 0%;
  height: 3px;
  transition: width 0.4s ease-in;
  margin: 5px 13px;
  color: white;

}

#menu .nav-menu .nav-list:hover::after {
  width: 80%;
  color: #f78a41;
}


#icon-menu #icon-list:hover {
  background-color: white;
  color: rgb(0, 0, 0);
  border-radius: 10%;
  transition: 0.8s;
  cursor: pointer;
}

/* ----------menu link end---------- */

/* --------drop down menu---------- */

.dropdown-menu {
  padding: 20px !important;
  text-align: center;
  width: 300px;
  border-radius: 15px !important;
  background-color: #fff;
  border: 0px solid black !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dropdown-menu li a {
  font-weight: bold;
  font-family: sans-serif;
  margin: 0 0px;
}

.dropdown-menu li a:hover {
  background-color: #f78a41 !important;
  color: white;
  transition: 0.30s;
}

/* --------drop-down menu end---------- */
/* ------------Form------------ */

/* JobApplicationForm.css */

.form-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.heading {
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  color: #f78a41;
  position: relative;
  margin-bottom: 30px;
}

.heading::after {
  content: "";
  position: absolute;
  left: 130px;
  top: 35px;
  height: 2px;
  width: 50%;
  background-color: #f78a41;

}

.thank-you-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #4CAF50;
}

.application-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-size: 14px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="file"],
textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  min-height: 80px;
  resize: vertical;
}

button.submit-btn {
  padding: 10px 20px;
  background-color: #f78a41;
  /* Orange color */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  align-self: center;
}

button.submit-btn:hover {
  background-color: #f78a41;
  /* Darker orange */
}

.submit-btn {
  background-color: #f78a41;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 3px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .form-container {
    padding: 15px;
    max-width: 100%;
  }

  .heading {
    font-size: 25px;
    font-weight: bold;
    font-family: sans-serif;
    color: #f78a41;
  }

  .heading::after {
    content: "";
    position: absolute;
    left: 86px;
    top: 35px;
    height: 2px;
    width: 50%;
    background-color: #f78a41;

  }

  button.submit-btn {
    font-size: 13px;
    padding: 8px 16px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="file"],
  textarea {
    font-size: 13px;
    padding: 8px;
  }

  label {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .form-container {
    padding: 10px;
  }

  .heading {
    font-size: 25px;
    font-weight: bold;
    font-family: sans-serif;
    color: #f78a41;
  }

  .heading::after {
    content: "";
    position: absolute;
    left: 86px;
    top: 35px;
    height: 2px;
    width: 50%;
    background-color: #f78a41;

  }

  button.submit-btn {
    font-size: 12px;
    padding: 6px 12px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="file"],
  textarea {
    font-size: 12px;
    padding: 6px;
  }

  label {
    font-size: 12px;
  }
}

.nav-tabs {
  border: 0px solid black !important;
  padding: 5px;
  border-radius: 5px;
}

.nav-item {
  margin: 1px 5px;
}

.nav-tabs .nav-link {
  border: 0px solid black !important;
  font-weight: bold;
  font-family: sans-serif;
}

.nav-tabs .nav-link:hover {
  background-color: #f78a41;
  color: white !important;
  transition: 0.5s;
}

/* ------------Form-end------------ */

/* ------laoder ------- */

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full height */
}

.spinner {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #f78a41;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ------laoder end------- */



/* ------About----- */
.About {
  overflow: hidden;
}

.info-box {
  color: white;
  /* border: 1px solid black; */
  background: rgba(34, 192, 195, 0.637);
  background: linear-gradient(185deg, rgba(34, 192, 195, 0.5) 49%, rgba(41, 232, 186, 1) 82%, rgba(45, 253, 181, 0.8575805322128851) 100%);
  border-radius: 20px;
  overflow: hidden;
}

/* -------Nav Tab----------- */
.bg-orange {
  background-color: #f78a41 !important;
  border-color: #f78a41 !important;
  color: white !important;
}


/* -------Services-------- */
.service-section h3 {
  font-family: sans-serif;
  font-size: 2rem;
  line-height: 1.2;
  /* Adjust line height for readability */
  margin: 0 0 25px;
  /* Reset margin if needed */
  text-align: center;
  font-weight: 500;
  color: #282c34;

}

.service .bttn {
  text-align: center;
  background-color: #f78a41;
  color: white;
  width: 20%;
  padding: 10px;
  border-radius: 50px;
  font-family: sans-serif;
  font-weight: 600;
  letter-spacing: 0.5PX;
  font-size: 15PX;
}

/* service button media query */
@media (max-width: 1200px) {
  .service .bttn {
    text-align: center;
    background-color: #f78a41;
    color: white;
    width: 75%;
    padding: 10px;
    border-radius: 50px;
    font-family: sans-serif;
    font-weight: 600;
    letter-spacing: 0.5PX;
    font-size: 15PX;
  }
}

@media (max-width: 900px) {
  .service .bttn {
    text-align: center;
    background-color: #f78a41;
    color: white;
    width: 75%;
    padding: 10px;
    border-radius: 50px;
    font-family: sans-serif;
    font-weight: 600;
    letter-spacing: 0.5PX;
    font-size: 15PX;
  }
}
@media (max-width: 600px) {
  .service .bttn {
    text-align: center;
    background-color: #f78a41;
    color: white;
    width: 75%;
    padding: 10px;
    border-radius: 50px;
    font-family: sans-serif;
    font-weight: 600;
    letter-spacing: 0.5PX;
    font-size: 15PX;
  }
}
/* service button media query end*/

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .service-section h3 {
    font-size: 1.8rem;
    text-align: center;
    font-weight: 500;
  }
}

@media (max-width: 900px) {
  .service-section h3 {
    font-size: 1.10 rem;
    text-align: center;
    font-weight: 500;
  }
}

@media (max-width: 600px) {
  .service-section h3 {
    font-size: 2.5rem;
    text-align: center;
    font-weight: 500;
  }
}

.img-box p {
  line-height: 25px;
  letter-spacing: 0.10px;
}
.img-box img{
  position: relative;
}
.img-box {
  cursor: pointer;
  transition: 0.5s;
  height: 300px !important;
  background-color: #f6f8f9;
  border-color: rgba(37, 37, 37, 0.2);
  border-radius: 10PX;
}

@keyframes shake {
  0% { transform: translate(0); }
  25% { transform: translate(-5px, 0); }
  50% { transform: translate(5px, 0); }
  75% { transform: translate(-5px, 0); }
  100% { transform: translate(0); }
}

.img-box {
  display: inline-block; /* Make sure the box wraps around the image */
  transition: transform 0.2s; /* Smooth transition for scaling */
}

.img-box:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.img-box:hover img {
  animation: shake 0.5s; /* Apply shake animation on the image */
}
/* -------Services-end-------- */
.footer ul li a {
  position: relative;
  /* To position the pseudo-element relative to the list item */
  display: inline-block;
  /* To ensure the text is inline and the line moves with the list item */
}

.footer ul li a {
  color: white;
  text-decoration: none;
  /* Remove underline from links */
  transition: color 0.3s ease;
  /* Smooth transition for color change */
}


.footer ul li a:hover {
  color: #f78a41;
  padding-left: 5px;
  transition: 0.1s all;
}

/* ------------About End---------- */



